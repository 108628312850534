import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import SgLink from "./common/sgLink"

function Footer() {
  const year = new Date().getFullYear()
  const {t} = useTranslation();
  return (
    <div className="flex text-sm w-full px-8 flex-row justify-between items-center flex-wrap">
      <div className="flex flex-wrap flex-row justify-between">
        <Link className="py-6 mr-3 gothic-bold" to="/cookies">{t('cookies')}</Link>
        <Link className="py-6 mr-3 gothic-bold" to="/data-protection-notice">{t('data_protection')}</Link>
        <Link className="py-6 mr-3 gothic-bold" to="/terms-of-use">{t('terms_of_use')}</Link>
      </div>
      <div className="m-2 lg:m-0">
        Copyright &copy; {year} skills4gigs. All Rights Reserved
      </div>
    </div>
  )
}

export default Footer