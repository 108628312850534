// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-data-protection-notice-tsx": () => import("./../../../src/pages/data-protection-notice.tsx" /* webpackChunkName: "component---src-pages-data-protection-notice-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-svg-hero-tsx": () => import("./../../../src/pages/svg/hero.tsx" /* webpackChunkName: "component---src-pages-svg-hero-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-why-video-learning-tsx": () => import("./../../../src/pages/why-video-learning.tsx" /* webpackChunkName: "component---src-pages-why-video-learning-tsx" */),
  "component---src-pages-work-together-tsx": () => import("./../../../src/pages/work-together.tsx" /* webpackChunkName: "component---src-pages-work-together-tsx" */)
}

